.App {
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  h1 {
    margin-top: 50px;
    margin-bottom: 30px;
  }
  
  label {
    font-size: 18px;
  }
  
  textarea {
    font-size: 16px;
  }
  
  button {
    margin-top: 20px;
    font-size: 18px;
    padding: 10px 20px;
    background-color: #4caf50;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  h2 {
    margin-top: 50px;
  }
  